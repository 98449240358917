import React from "react"
import Helmet from "react-helmet"
import HeaderImage from "../images/OrangeCouchOG.jpg"
import TwitterImage from "../images/OrangeCouchTwitter.jpg"

const OrangeCouchHead = () => (
  <Helmet>
    {/* <!-- Primary Meta Tags --> */}
    <title>Orange Couch Talks</title>
    <meta name="title" content="Orange Couch Talks" />
    <meta name="description" content="A deeper look at Sunday's content." />
    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta
      property="og:url"
      content="https://www.crosschurchcares.com/orangecouchtalks"
    />
    <meta property="og:title" content="Orange Couch Talks" />
    <meta
      property="og:description"
      content="A deeper look at Sunday's content."
    />
    <meta property="og:image" content={HeaderImage} />
    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary" />
    <meta
      property="twitter:url"
      content="https://www.crosschurchcares.com/orangecouchtalks"
    />
    <meta property="twitter:title" content="Orange Couch Talks" />
    <meta
      property="twitter:description"
      content="A deeper look at Sunday's content."
    />
    <meta property="twitter:image" content={TwitterImage} />
  </Helmet>
)

export default OrangeCouchHead
